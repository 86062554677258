import { useState, useEffect, useMemo } from 'react';
import Styles from "./TorrentPage.module.css";
import Search from '../../Components/Search/Search';
import { TYPES, API_URLS } from '../../Constants';
import Torrent from '../../Components/Torrent/Torrent';
import Loader from "../../Assets/Imgs/loader.gif";

const TorrentPage = () => {

  const [ input, setInput ] = useState( "" );
  const [ searchResults, setSearchResults ] = useState( null );
  const [ torrentLoading, setTorrentLoading ] = useState( true );
  const [ torrentNextPageLoading, setTorrentNextPageLoading ] = useState( false );
  const [ Filter, setFilter ] = useState( "All" );
  const Filters = useMemo( () => [ 'All', 'Movies', 'TV', 'Music', 'Apps', 'Anime', "Other" ], [] );
  const [ loadMore, setLoadMore ] = useState( false );
  // const [ paused, setPaused ] = useState( false );
  const [ currentPage, setCurrentPage ] = useState( {
    type: TYPES.TORRENT_SEARCH,
    hasNextPage: false,
    pageNum: 1
  } );

  useEffect( () => {
    if ( loadMore === false ) return;

    if ( currentPage.hasNextPage ) {
      fetchTorrents( { controller: null, page: currentPage.pageNum + 1 } );
    }

  }, [ loadMore ] );


  const FilterTypes = useMemo( () => ( {
    All: "all",
    Apps: "applications",
    Movies: "movies",
    Anime: "anime",
    Music: "music",
    TV: "tv",
    Other: "other"
  } ), [] );

  const fetchTorrents = async ( { controller, query = input, category = Filter, page = 1 } ) => {
    // setPaused( true );
    if ( page <= 1 ) {
      setTorrentLoading( true );
    } else {
      setTorrentNextPageLoading( true );
    }

    try {
      const response = await fetch( API_URLS.TORRENT_SEARCH, {
        method: "POST",
        body: JSON.stringify( {
          query,
          page,
          category: FilterTypes[ category ]
        } ),
        headers: {
          "Content-Type": "application/json" // Set content type header
        },
        signal: controller?.signal,
        referrerPolicy: "unsafe-url"
      } );

      if ( response.ok ) {
        const body = await response.json();

        if ( !body?.torrents?.length ) {
          setCurrentPage( prev => ( {
            ...prev,
            hasNextPage: false
          } ) );

          controller?.abort();
          return;
        } else {
          if ( page == 1 ) setSearchResults( body );
          else setSearchResults( prev => ( {
            hasNextPage: body.hasNextPage,
            currentPage: body.currentPage,
            torrents: [
              ...prev.torrents,
              ...( body?.torrents?.length && ( body.torrents?.[ 0 ]?.Url != prev.torrents?.[ 0 ]?.Url ) ? body.torrents : [] )
            ]
          } ) );

          setCurrentPage( prev => ( {
            hasNextPage: body.hasNextPage,
            pageNum: body.currentPage,
            type: prev.type
          } ) );
        }

        setTorrentLoading( false );

      }
    } catch ( e ) {
      if ( e.name != "AbortError" ) console.log( e );
    } finally {
      // setPaused( false );
      setLoadMore( false );
      setTorrentNextPageLoading( false );
    }
  };

  const handleSearch = ( e, searchBy ) => {
    if ( e.key == "Enter" && input.trim().length ) {
      fetchTorrents( { controller: null } );
    }
  };

  const handleFilterClick = ( category ) => {
    setFilter( category );
  };


  useEffect( () => {
    const controller = new AbortController();

    if ( input.trim().length ) {

      fetchTorrents( { controller } );

    } else {
      fetchTorrents( { controller: null, query: "" } );
    }

    return () => controller.abort();

  }, [ input, Filter ] );


  return (
    <>
      <img src={ require( "../../Assets/Imgs/bg-10-op.png" ) } className={ Styles[ 'bg-img' ] } alt="bg" />
      <div className={ Styles[ "bg" ] }>
        <div className={ Styles[ "hero" ] }>
          <p className={ Styles[ "punchline" ] }>Experience the power of torrents - fast, secure, and limitless.</p>
          <Search
            handleSearch={ handleSearch }
            input={ input }
            setInput={ setInput }
            className={ Styles[ "inputs" ] }
            placeholder='Search Anime'
          />
        </div>
      </div>
      <div className={ Styles[ "torrent-section" ] }>
        <div className={ Styles[ "filters" ] }>
          { Filters.map( ( filter, key ) => (
            <button type='button' key={ key } onClick={ () => handleFilterClick( filter ) } className={ `${ Styles[ 'filter-btn' ] } ${ filter === Filter && Styles[ "active" ] }` }>{ filter }</button>
          ) ) }
        </div>
        { torrentLoading && (
          <img
            src={ Loader }
            alt="loading"
            width={ 140 }
            height={ 140 }
            style={ {
              position: "relative",
              top: "0",
            } }
          />
        ) }
        { !torrentLoading && (
          <div className={ Styles[ "torrents-container" ] }>
            { searchResults?.torrents?.length && searchResults?.torrents?.map( ( torrent, key ) => (
              <Torrent torrent={ torrent } key={ key } />
            ) ) }
            { torrentNextPageLoading ? (
              <img
                src={ Loader }
                alt="loading"
                width={ 130 }
                height={ 130 }
                style={ {
                  position: "relative",
                  top: "0",
                } }
              />
            ) : (
              <>
                { currentPage.hasNextPage && (
                  <button type='button' className={ Styles[ 'load-more-btn' ] } onClick={ () => setLoadMore( true ) } disabled={ loadMore }>Load More</button>
                ) }
              </>
            ) }

          </div>
        ) }

      </div>
    </>
  );
};

export default TorrentPage;